import React from "react"
import { Link, graphql } from "gatsby"
import ContentContainer from "../components/contentContainer"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import placeholder from "../images/logo.png"
import FluidContainer from "../components/fluidContainer"
import SEO from "../components/seo"
import moment from "moment"

const BioContainer = styled.div`
  background-color: #eaecec;
  padding: 2rem;
  font-family: roboto;
  font-size: 16px;
  font-weight: 300;
  color: black;
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`

const BibSheetContainer = styled.div`
  font-family: roboto;
  font-size: 20px;
`

const NameContainer = styled.h1`
  background: #003357;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 24px;
  width: 70%;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);

  @media (max-width: 568px) {
    width: 100%;
  }
`

const BioImageContainer = styled.div`
  flex: 1 0 30%;
  order: 1;
  background-image: url(${props => props.background});
  width: 250px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
  }
`

const RecordContainer = styled.div`
  background-color: #eaecec;
  padding: 2rem;
  font-family: roboto;
`

const RecordListContainer = styled.div`
  padding-top: 2rem;
  background-color: white;
  font-size: 16px;
`

const RecordList = styled.ul`
  padding: 0 2rem 2rem;
  color: black;
  list-style: none;
  @media (max-width: 568px) {
    width: 100%;
    padding: 0 1rem 1rem;
    margin: 0;
  }
`

const RecordListItem = styled.li`
  @media (max-width: 568px) {
    width: 100%;
    padding: 0.5rem 0;
  }
`

const returnAge = data => {
  if (data.birthday) {
    return moment().diff(data.birthday, "years")
  } else if (data.age) {
    return data.age
  } else {
    return null
  }
}

const ProfileTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={`${data.strapiProfiles.nameF} ${data.strapiProfiles.nameL}`}
      keywords={[`eating`, `contests`, `official`]}
    />
    <FluidContainer>
      <ContentContainer>
        <div
          css={css`
            text-align: right;
          `}
        >
          <Link
            css={css`
              font-family: roboto;
              color: white;
              display: inline-block;
              padding: 0 0.7rem;
              border-radius: 5px;
              background: rgba(255, 255, 255, 0.3);
              text-decoration: none;
              margin-bottom: 0.5rem;
            `}
            to="/eaters"
          >
            Back
          </Link>
        </div>
        <BioContainer>
          <div style={{ order: "2", flex: "1 0 65%" }}>
            <NameContainer>
              {data.strapiProfiles.nameF} {data.strapiProfiles.nameL}
            </NameContainer>
            <BibSheetContainer>
              Bib Sheet
              <br />
              <br />
              {data.strapiProfiles.hometown}
              <br />
              Age: {returnAge(data.strapiProfiles)}
              <br />
              Weight: {data.strapiProfiles.weight}
              <br />
              <br />
            </BibSheetContainer>
            <p
              css={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{ __html: data.strapiProfiles.bio }}
            />
          </div>
          <BioImageContainer
            background={
              (data.strapiProfiles.image &&
                data.strapiProfiles.image.publicURL) ||
              placeholder
            }
          />
        </BioContainer>
        {!!data.strapiProfiles.records.length && (
          <RecordContainer>
            <RecordListContainer>
              <h2
                css={css`
                  background-color: #b5232b;
                  font-size: 24px;
                  max-width: 40%;
                  min-width: 180px;
                  padding: 0.5rem 2rem;
                  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
                `}
              >
                Records
              </h2>
              <RecordList>
                {data.strapiProfiles.records.map((r, i) => (
                  <RecordListItem key={i}>
                    <span
                      css={css`
                        background: #003357;
                        padding: 0.1rem 0.5rem;
                        border-radius: 5px;
                        color: white;
                      `}
                    >
                      {r.food}
                    </span>
                    &nbsp;
                    <span>
                      {r.description} / {r.time}
                    </span>
                  </RecordListItem>
                ))}
              </RecordList>
            </RecordListContainer>
          </RecordContainer>
        )}
      </ContentContainer>
    </FluidContainer>
  </Layout>
)

export default ProfileTemplate

export const query = graphql`
  query ProfileQuery($id: String!) {
    strapiProfiles(id: { eq: $id }) {
      nameF
      nameL
      rank
      birthday
      bio
      age
      hometown
      height
      weight
      image {
        publicURL
      }
      records {
        id
        food
        description
        time
      }
    }
  }
`
